html,
body {
  height: 100%;
}

body,
p,
ul,
span {
  font-family: $font-family-regular;
  line-height: $line-height;
}

h1 {
  font-family: $font-family-medium;
  font-size: $h1-font-size;
  line-height: $line-height;
}

h2 {
  font-family: $font-family-medium;
  line-height: $line-height;
}

h3 {
  font-family: $font-family-medium;
  line-height: $line-height;
  font-size: $h3-font-size;
}

p,
label {
  font-family: $font-family-regular;
  font-size: $p-font-size;
  line-height: $line-height;
}

a {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;

  &.dark-link {
    font-family: $font-family-medium;
    font-size: 11px;
    color: $color-gray-1;
  }
  &.light-link {
    font-family: $font-family-medium;
    font-size: 13px;
    color: $color-ocean-green-40;
  }
  &.tertiary-text {
    font-family: $font-family-medium;
  }
}

small, .small {
  font-family: $font-family-regular;
  font-size: $small-font-size;
  line-height: $line-height;
}

ul {
  &.default-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid $color-gray-2;
      .list-name {
        color: $color-gray-3;
      }
      .list-detail {
        font-family: $font-family-medium;
      }
    }
  }
  &.default-list-with-out-underline {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .list-name {
        font-family: $font-family-medium;
      }
      .list-detail {
        font-family: $font-family-medium;
      }
    }
  }
}

// Text align

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

::ng-deep {
  ngx-extended-pdf-viewer #mainContainer {
    min-width: unset;
  }
}
