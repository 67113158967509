$tooltip-background: #F3F5F6;
$tooltip-arrow-width: 8px;
$box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15);
.container-tooltip {
  display: inherit;
}

.ng-tooltip {
  position: absolute;
  width: 250px;
  font-size: 14px;
  text-align: center;
  color: $color-common;
  padding: 14px 16px;
  background: $tooltip-background;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
  box-shadow: $box-shadow;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: $tooltip-arrow-width * -1;
  border-width: $tooltip-arrow-width;
  border-color: $tooltip-background transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  right: 20px;
  margin-left: $tooltip-arrow-width * -1;
  border-width: $tooltip-arrow-width;
  border-color: transparent transparent $tooltip-background transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: $tooltip-arrow-width * -1;
  border-width: $tooltip-arrow-width;
  border-color: transparent transparent transparent $tooltip-background;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: $tooltip-arrow-width * -1;
  border-width: $tooltip-arrow-width;
  border-color: transparent $tooltip-background transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}


.ng-tooltip-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}