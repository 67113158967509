.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .form-relative {
    position: relative;
    display: grid;
  }
}

.form-control {
  display: block;
  font-size: $p-font-size;
  font-weight: 500;
  line-height: 1.5;
  color: $color-common;
  background-color: $color-common-contrast;
  background-clip: padding-box;
  border-radius: $border-radius;
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: $font-family-regular;
  border: 1px solid $color-gray-6;
  position: relative;
  padding: 12px 14px;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;

  &.lg {
    padding: 14px 18px;
    height: 56px;
    font-size: 16px;
  }
  &.md {
    padding: 10px 14px;
    height: 46px;
    font-size: 13px;
  }
  &.sm {
    padding: 5px 12px;
    font-size: 12px;
    height: 28px;
  }
  // &.light {
  //   background-color: white;
  //   border: 1px solid $neutral-30;
  //   &:hover {
  //     background-color: $neutral-20;
  //   }
  // }
  // &.primary {
  //   background-color: $success-10;
  //   border: 1px solid $success-10;
  //   i,
  //   .item-option {
  //     color: $success-80;
  //     font-family: $roboto-medium;
  //   }
  //   &:hover {
  //     background-color: $success-20;
  //   }
  // }
  // &:hover {
  //   background-color: $neutral-30;
  // }
  // &.error-control {
  //   border: 1px solid $error-60;
  // }

  // avoid inputs arrow show
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus,
  &:focus-visible {
    outline: 0;
    border: 2px solid $color-primary;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.13);
  }

  &.disabled {
    opacity: 0.5;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-gray-5;
    font-size: $p-font-size;
    font-family: $font-family-regular;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-gray-5;
    font-size: $p-font-size;
    font-family: $font-family-regular;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $color-gray-5;
    font-size: $p-font-size;
    font-family: $font-family-regular;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $color-gray-5;
    font-size: $p-font-size;
    font-family: $font-family-regular;
  }
}

.form-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}

mo-button {
  display: block;
}
