/* ****************************************************
 * Ocean green
 * ****************************************************/

$color-ocean-green-10: #e2f5f1;
$color-ocean-green-20: #b8e7dc;
$color-ocean-green-30: #8ad8c6;
$color-ocean-green-40: #5bc8af;
$color-ocean-green-50: #39ba9d;
$color-ocean-green-60: #259f80;
$color-ocean-green-70: #208e70;
$color-ocean-green-80: #1b7d62;

/* ****************************************************
 * Aquamarine
 * ****************************************************/

$color-aquamarine-10: #e0fcf3;
$color-aquamarine-20: #b2f7e1;
$color-aquamarine-30: #74f2ce;
$color-aquamarine-40: #00ecbb;
$color-aquamarine-50: #00e3ab;
$color-aquamarine-60: #00cb8d;
$color-aquamarine-70: #00b67d;
$color-aquamarine-80: #00a46f;

/* ****************************************************
 * Amethyst
 * ****************************************************/

$color-amethyst-10: #f0e4f6;
$color-amethyst-20: #d8bbe9;
$color-amethyst-30: #bf8fdb;
$color-amethyst-40: #a663cc;
$color-amethyst-50: #9342c1;
$color-amethyst-60: #7222af;
$color-amethyst-70: #5d1ea6;
$color-amethyst-80: #491b9e;

/* ****************************************************
 * Orchid
 * ****************************************************/

$color-orchid-10: #e3b9e8;
$color-orchid-20: #d189d9;
$color-orchid-30: #be58ca;
$color-orchid-40: #af2bbf;
$color-orchid-50: #a100b4;
$color-orchid-60: #9300af;
$color-orchid-70: #7e00a8;
$color-orchid-80: #6c00a2;

/* ****************************************************
 * Sky Blue
 * ****************************************************/

$color-sky-blue-10: #e0fcf3;
$color-sky-blue-20: #bffff1;
$color-sky-blue-30: #9cffe9;
$color-sky-blue-40: #7ffcdf;
$color-sky-blue-50: #68efcb;
$color-sky-blue-60: #61debb;
$color-sky-blue-70: #59caa8;
$color-sky-blue-80: #53b999;

/* ****************************************************
 * Principal colors, usually for theme the app
 * ****************************************************/

$color-corporate: #dd052b;
$color-primary: $color-amethyst-40;
$color-purple-complementary: $color-orchid-40;
$color-green-complementary: $color-ocean-green-40;
$color-background: $color-amethyst-10;




// Color for general text, paragraphs, etc.
$color-common: #000;
$color-common-contrast: white;

// Color primary, usually for navs, headers, buttons
$color-primary: #9342c1;
$color-primary-10: #F0E4F6;
$color-primary-contrast: #fff;

//color primary opacity for buttons/links in help view
$color-primary-opacity: #f3f3f3;

// Color secondary, usually to contrast or join with primary color
$color-secondary: #AF2BBF;
$color-secondary-contrast: #fff;

// Color tertiary, usually to emphasis some buttons or actions
$color-tertiary: #5BC8AF;
$color-tertiary-contrast: white;

/* ****************************************************
 * State colors, usually to represent status, or actions
 * ****************************************************/

$color-active: #00d182;
$color-active-contrast: white;

$color-warning: #ffa300;
$color-warning-contrast: white;

$color-info: #1d9ac1;
$color-info-contrast: white;

$color-alert: #f95f62;
$color-alert-contrast: white;

$color-default: #e5e5e5;
$color-default-contrast: #283552;

$color-disabled: #e5e5e5;
$color-disabled-contrast: white;

/* ****************************************************
 * Gray tones to use in shadows, borders, lines, etc
 * ****************************************************/
$color-gray-1: #252525;
$color-gray-2: #424242;
$color-gray-3: #9ba2af;
$color-gray-4: #a2a2a2;
$color-gray-5: #e5e5e5;
$color-gray-6: #d8d8d8;
$color-gray-7: #f6f6f6;

$colors: (
  primary: (
    color: $color-primary,
    contrast: $color-primary-contrast,
  ),
  secondary: (
    color: $color-secondary,
    contrast: $color-secondary-contrast,
  ),
  tertiary: (
    color: $color-tertiary,
    contrast: $color-tertiary-contrast,
  )
);
