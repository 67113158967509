$sizes: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  80,
  100
); // Adjust this to include the pixel amounts you need.

// Example uses : .font-size-30 (Font size 30px)

@each $size in $sizes {
  .font-size-#{$size} {
    font-size: #{$size}px !important;
  }
}
