$max-content: 1040px;
$min-heigh-card: 68vh;
$min-heigh-card-home: 54vh;

$gutter-10: 0.7rem;
$gutter-16: 1rem;
$gutter-20: 1.5rem;
$gutter-30: 1.8rem;
$gutter-40: 2.5rem;
$gutter-80: 5rem;

$padding: 1rem;
$margin: 1rem;
$border-radius: 8px;
$height-navbar: 4.2rem;
$width-menu-collapsed: 5rem;
$width-menu-expanded: 14rem;
$height-title: 4rem;

$z-index: (
  modal: 1000,
  notification: 2000,
  navbar: 6,
  backdrop: 4,
  backdrop-header: 7,
  sidebar: 5,
  menu-options: 11,
  button-float: 3,
);
