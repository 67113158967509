$icomoon-font-family: "promujer" !default;
$icomoon-font-path: "../../fonts/icons" !default;

$icon-loading: "\e900";
$icon-menu: "\e902";
$icon-whatsapp: "\e901";
$icon-edit: "\e903";
$icon-view: "\e904";
$icon-share: "\e905";
$icon-question: "\e906";
$icon-phone: "\e907";
$icon-lupa: "\e908";
$icon-logout: "\e909";
$icon-left: "\e90a";
$icon-hidden: "\e90b";
$icon-close: "\e90c";
$icon-Clock: "\e90d";
$icon-check-mark: "\e90e";
$icon-bars: "\f0c9";
$icon-navicon: "\f0c9";
$icon-reorder: "\f0c9";
$icon-caret-down: "\f0d7";
$icon-caret-up: "\f0d8";
$icon-caret-left: "\f0d9";
$icon-caret-right: "\f0da";
$icon-envelope: "\f0e0";
$icon-spinner6: "\e97f";
$icon-spinner7: "\e980";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?pvkou9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?pvkou9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?pvkou9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mo-loading-arrow {
  &:before {
    content: $icon-loading; 
  }
}
.mo-menu {
  &:before {
    content: $icon-menu; 
  }
}
.mo-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.mo-edit {
  &:before {
    content: $icon-edit; 
  }
}
.mo-view {
  &:before {
    content: $icon-view; 
  }
}
.mo-share {
  &:before {
    content: $icon-share; 
  }
}
.mo-question {
  &:before {
    content: $icon-question; 
  }
}
.mo-phone {
  &:before {
    content: $icon-phone; 
  }
}
.mo-lupa {
  &:before {
    content: $icon-lupa; 
  }
}
.mo-logout {
  &:before {
    content: $icon-logout; 
  }
}
.mo-left {
  &:before {
    content: $icon-left; 
  }
}
.mo-hidden {
  &:before {
    content: $icon-hidden; 
  }
}
.mo-close {
  &:before {
    content: $icon-close; 
  }
}
.mo-Clock {
  &:before {
    content: $icon-Clock; 
  }
}
.mo-check-mark {
  &:before {
    content: $icon-check-mark; 
  }
}
.mo-bars {
  &:before {
    content: $icon-bars; 
  }
}
.mo-navicon {
  &:before {
    content: $icon-navicon; 
  }
}
.mo-reorder {
  &:before {
    content: $icon-reorder; 
  }
}
.mo-caret-down {
  &:before {
    content: $icon-caret-down; 
  }
}
.mo-caret-up {
  &:before {
    content: $icon-caret-up; 
  }
}
.mo-caret-left {
  &:before {
    content: $icon-caret-left; 
  }
}
.mo-caret-right {
  &:before {
    content: $icon-caret-right; 
  }
}
.mo-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.mo-spinner6 {
  &:before {
    content: $icon-spinner6; 
  }
}
.mo-spinner7 {
  &:before {
    content: $icon-spinner7; 
  }
}
