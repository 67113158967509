@font-face {
    font-family: 'gothamblack';
    src: url('/app/presentation/assets/fonts/gotham/gotham-black-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothambold';
    src: url('/app/presentation/assets/fonts/gotham/gotham-bold-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothambold';
    src: url('/app/presentation/assets/fonts/gotham/gothambold-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothambold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothambold_italic';
    src: url('/app/presentation/assets/fonts/gotham/gothambolditalic-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothambolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothambook';
    src: url('/app/presentation/assets/fonts/gotham/gothambook-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothambook-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothambookitalic';
    src: url('/app/presentation/assets/fonts/gotham/gotham-bookitalic-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-bookitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothambook_italic';
    src: url('/app/presentation/assets/fonts/gotham/gothambookitalic-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothambookitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothamlight';
    src: url('/app/presentation/assets/fonts/gotham/gotham-light-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothamlight';
    src: url('/app/presentation/assets/fonts/gotham/gothamlight-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothamlight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothamlight_italic';
    src: url('/app/presentation/assets/fonts/gotham/gothamlightitalic-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothamlightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothammedium';
    src: url('/app/presentation/assets/fonts/gotham/gothammedium-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothammedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothammedium';
    src: url('/app/presentation/assets/fonts/gotham/gothammedium_1-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothammedium_1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothammedium_italic';
    src: url('/app/presentation/assets/fonts/gotham/gothammediumitalic-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gothammediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothamthin';
    src: url('/app/presentation/assets/fonts/gotham/gotham-thin-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothamthinitalic';
    src: url('/app/presentation/assets/fonts/gotham/gotham-thinitalic-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothamultraitalic';
    src: url('/app/presentation/assets/fonts/gotham/gotham-ultraitalic-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-ultraitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothamxlight';
    src: url('/app/presentation/assets/fonts/gotham/gotham-xlight-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-xlight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'gothamxlightitalic';
    src: url('/app/presentation/assets/fonts/gotham/gotham-xlightitalic-webfont.woff2') format('woff2'),
      url('/app/presentation/assets/fonts/gotham/gotham-xlightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  