$font-family-title: 'gothambold', sans-serif;
$font-family-light: 'gothamxlight', sans-serif;
$font-family-regular: 'gothambook', sans-serif;
$font-family-medium: 'gothammedium', sans-serif;
$font-family-bold: 'gothambold', sans-serif;

$line-height: 1.5em;

$h1-font-size: 26px;
$h2-font-size: 21px;
$h3-font-size: 18px;
$h4-font-size: 15px;
$p-font-size: 13px;
$button-font-size: 15px;
$small-font-size: 10px;
