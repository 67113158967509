
.mo-loading {
    width: 35px;
    height: 35px;
    display: inline-block;
    border: 5px solid $color-amethyst-30;
    border-left-color: $color-amethyst-50;
    border-top-color: $color-amethyst-50;
    border-radius: 50%;
    animation: rotate 600ms infinite linear;
    position: absolute;
    top: 6px;
    right: 17px;
    &.size-sm {
      width: 14px;
      height: 14px;
      top: 6px;
      right: 9px;
    }
    &.size-md {
      width: 18px;
      height: 18px;
      top: 10px;
      right: 18px;
    }
    &.size-lg {
      width: 26px;
      height: 26px;
      top: 12px;
      right: 16px;
    }
    &.light {
      border: 5px solid $color-orchid-10;
      border-left-color: $color-orchid-20;
      border-top-color: $color-orchid-40;
    }
  }
  
  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }
  