.mat-modal {
  padding: 5vw;
  max-width: none !important;

  .mat-dialog-container {
    border-radius: $border-radius !important;
    padding: 0;
    overflow: hidden;
  }
}

.mat-dialog-title {
  font-family: $font-family-medium !important;
  text-align: center;
  margin: 0 0 5px !important;
}

.mat-select-panel .mat-option {
  margin: 5px 11px;

  &.mat-selected:not(.mat-option-multiple),
  &.mat-active {
    background-color: $color-primary-10;
    border-radius: 6px;
  }
}
.mat-simple-snackbar-success {
  width: 50%;
  background-color: $color-orchid-10;
  text-align: center;
  .mat-simple-snackbar {
    color: $color-amethyst-60;
  }
}

//PDF Viewer Mobile Styles

button#print,
button#print * {
  display: block !important;
}

button#download,
button#download * {
  display: block !important;
}

#toolbarViewerMiddle {
  display: none;
}

#mainContainer {
  min-width: unset !important;
}

#toolbarContainer {
  display: none !important;
}
