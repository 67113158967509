.center {
  margin: 0 auto;
}

.hidden {
  display: none;
}

.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container-layout-primary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-background;
  height: 100vh;
  &.image-pattern {
    background-color: #fff;
    background-image: url('../../backgrounds/bg-primary.png');
    background-repeat: repeat;
  }
}

.box-layout-content {
  height: 92vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 5vw;
  padding-right: 5vw;
}

.box-content-shadow {
  background: #ffffff;
  box-shadow: 0px 2px 4px #979797;
  border-radius: 10px;
  padding: 33px 38px;
  margin-bottom: 15px;
  margin-top: 15px;
  &.without-shadow {
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    padding: 0;
    margin: 0;
  }
}
.box-content-shadow-large-cards {
  background: #ffffff;
  box-shadow: 0px 2px 4px #979797;
  border-radius: 10px;
  padding: 33px 18px;
  margin-bottom: 15px;
  margin-top: 15px;
  &.without-shadow {
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 990px) {
  .hidden-lg {
    display: none;
  }
}
@media (max-width: 990px) {
  .hidden-md {
    display: none;
  }
}

.relative-box {
  position: relative;
}
